import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часто задаваемые вопросы в Horizon Realty
			</title>
			<meta name={"description"} content={"Найдите ответы на часто задаваемые вопросы в Horizon Realty\n"} />
			<meta property={"og:title"} content={"FAQ | Часто задаваемые вопросы в Horizon Realty"} />
			<meta property={"og:description"} content={"Найдите ответы на часто задаваемые вопросы в Horizon Realty\n"} />
			<meta property={"og:image"} content={"https://brewaup.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://brewaup.com/img/2389056-200.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://brewaup.com/img/2389056-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://brewaup.com/img/2389056-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://brewaup.com/img/2389056-200.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://brewaup.com/img/2389056-200.png"} />
			<meta name={"msapplication-TileImage"} content={"https://brewaup.com/img/2389056-200.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://brewaup.com/img/2.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="760px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					FAQ
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Часто задаваемые вопросы
				</Text>
				<Text font="--base">
					Добро пожаловать в раздел часто задаваемых вопросов Horizon Realty, где мы ответим на ваши самые насущные вопросы с ясностью и компетентностью. Независимо от того, являетесь ли вы начинающим покупателем, ориентирующимся в сложностях рынка, или опытным продавцом, ищущим стратегии для максимизации стоимости вашей недвижимости, наши часто задаваемые вопросы предлагают комплексные решения, которые помогут вам на каждом шагу.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
				FAQ
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Как начать процесс покупки жилья?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Начните свой путь, обратившись к нашей команде опытных агентов. Мы будем работать в тесном контакте с вами, чтобы понять ваши предпочтения и провести вас через весь процесс, от первоначального поиска до закрытия сделки.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Что я должен учесть при продаже недвижимости?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Продажа недвижимости требует тщательного планирования и подготовки. Наша команда поможет вам оценить рыночные тенденции, установить правильную цену и оформить ваш дом, чтобы привлечь потенциальных покупателей.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Предлагаете ли вы услуги по управлению недвижимостью?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Да, мы предоставляем комплексные услуги по управлению недвижимостью, чтобы помочь вам максимально увеличить стоимость ваших инвестиций. Мы профессионально и внимательно относимся ко всем аспектам управления недвижимостью - от проверки арендаторов до технического обслуживания.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Могу ли я сдавать свою недвижимость в аренду через Horizon Realty?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Конечно! Мы предлагаем услуги по управлению арендой для владельцев недвижимости, желающих сдать в аренду свои дома или инвестиционные объекты. Позвольте нам позаботиться о поиске квалифицированных арендаторов и управлении договорами аренды от вашего имени.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Что отличает Horizon Realty от других агентств недвижимости?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						В Horizon Realty мы ставим во главу угла индивидуальный подход, честность и прозрачность. Наша преданная команда делает все возможное, чтобы каждый клиент получал внимание и поддержку, которых он заслуживает на протяжении всего своего пути в сфере недвижимости.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Предлагаете ли вы консультации для начинающих покупателей?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Да, мы предоставляем индивидуальные консультации для тех, кто только начинает работать на рынке недвижимости. Наши специалисты проведут вас через весь процесс, предложат ценные идеи и помогут принять взвешенное решение.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});